import React, { Component } from 'react'
import { allSessionDetails, lastUpdated } from '../lib/queries'

export default class SessionTable extends Component {
  constructor() {
    super()
    this.state = {
      lastUpdated: 0,
      sessionDetails: []
    }
  }
  
  componentDidMount() {
    this.updateComponent()
    this.timer = setInterval(() => this.updateComponent(), 20000)
  }
  
  updateComponent = async () => {
    const checkLastUpdated = await lastUpdated()
    if (this.state.lastUpdated !== checkLastUpdated) {
      this.setState({lastUpdated: checkLastUpdated})
      const updatedAllSessionDetails = await allSessionDetails()
      this.setState({sessionDetails: updatedAllSessionDetails})
    }
  }

  render() {

    // Return blank if there are no session details
    if (this.state.sessionDetails.length < 1) {
      return
    }

    const sessionDetails = this.state.sessionDetails
    
    // Generate a component for a row in the sessions table
    const SessionTableRow = (givenSession) => {
      const session = givenSession.session
      const classNames = 
        (session.isActive ? 'is-active ' : '') + 
        (session.isCurrent ? 'is-current ' : '') + 
        (session.isQueued ? 'is-queued ' : '') + 
        (session.isBookedOut ? 'is-booked-out ' : '') +
        (session.isComplete ? 'is-complete ' : '')
      //const availability = (session.isBookedOut ? 'Booked out' : session.available )
      return (
        <tr className={classNames}>
          <td>
            <time>{session.time}</time>
          </td>
          <td className={ session.availableClass }>
          { session.available } / { session.regularCapacity }
          </td>
          <td>
            { session.reserved } / { session.reservedCapacity }
          </td>
          <td>
            { session.scans } / { session.capacity }
          </td>
          <td className='booking-link'>
            {!session.isComplete && (
              <a href={session.bookingURL} target="_blank" rel="noreferrer"><span>Book ticket</span></a>
            )}
          </td>
        </tr>
      ) 
    }

    const tableRows = sessionDetails.map((session) => (
      <SessionTableRow session={session} />
    ))

    return(
      <>
        <table>
          <thead>
            <tr>
              <th scope="col">Session</th>
              <th scope="col">Available</th>
              <th scope="col">Reserved</th>
              <th scope="col">Scanned</th>
              <th scope="col"><span className='visually-hidden'>Book a ticket</span></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </>
    )
  }
}
