import React from 'react'
import CurrentSession from '../components/CurrentSession'
import QueuedSessions from '../components/QueuedSessions'

export default function Display() {
  return (
    <div className="display-wrapper">
      <header>
        <h1 className='visually-hidden'>Ticket display</h1>
      </header>
      <main>
      <CurrentSession />
      <QueuedSessions />
    </main>
    </div>
  )
}

