export const AWMQRCode = () => (
  <div className='qr-code'>
    <div>
      <p>Scan to book a ticket:</p>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1147 1147" shapeRendering="crispEdges" role="img" fill="#fff" >
	<g>
		<path d="M651.9,611c-10.6,0-16.3-5.3-77.8-5.3c-44.8,0-80.3,5.2-80.3,5.2h-60.9v-11.7h3.9v-32.8h39.4v-5.7h4.1v-3.9h37.6v-39.5
			h3.1v-7h9.3V498h8.3v-11.4h3.9c4.7-15.3,25.1-19.5,25.1-19.5V465h11.9v2.1c0,0,20.5,4.2,25.1,19.5h3.9V498h8.3v12.2h9.3v7h3.1
			v39.5H667v3.9h4.1v5.7h39.4v32.8h3.9v11.7L651.9,611L651.9,611z M574.3,543.4c-14.4,0-13,7.8-13,7.8v37.4h26v-37.4
			C587.3,551.2,588.7,543.4,574.3,543.4L574.3,543.4z"/>
		<path d="M440.8,680h3.5l5.7-16.8l5.7,16.8h3.5l8.1-23.6h-4.3l-5.6,17.4l-5.7-17.4h-3.5l-5.7,17.4l-5.6-17.4h-4.4
			L440.8,680L440.8,680z M467,679.9h4.2l2.4-5.7h11l2.4,5.7h4.3l-10.3-23.6h-3.8L467,679.9L467,679.9z M475.1,670.6l4-9.4l4,9.4
			H475.1L475.1,670.6z M494.9,679.9h4.1v-8.2h5.1h0.1l5.7,8.2h4.8l-6.3-8.9c3.3-0.9,5.6-3.2,5.6-7.1v-0.1c0-2-0.7-3.8-1.9-5.1
			c-1.5-1.5-3.8-2.3-6.7-2.3h-10.4V679.9L494.9,679.9z M499,668.1v-7.9h6c3,0,4.8,1.4,4.8,3.9v0.1c0,2.4-1.9,3.9-4.8,3.9H499
			L499,668.1z M529,679.9h4v-16.7l7.3,11h0.1l7.4-11v16.8h4.1v-23.4h-4.4l-7.1,11.1l-7.1-11.1H529V679.9L529,679.9z M558,679.9h17.5
			v-3.7h-13.4v-6.3h11.7v-3.7h-11.7v-6.1h13.2v-3.7H558V679.9L558,679.9z M580.4,679.9h4v-16.7l7.3,11h0.1l7.4-11v16.8h4.1v-23.4
			h-4.4l-7.1,11.1l-7.1-11.1h-4.4V679.9L580.4,679.9z M620.5,680.3c7.2,0,12.2-5.5,12.2-12.1v-0.1c0-6.6-5-12-12.2-12
			s-12.2,5.5-12.2,12.1v0.1C608.3,674.8,613.3,680.3,620.5,680.3L620.5,680.3z M620.5,676.5c-4.6,0-7.9-3.8-7.9-8.3v-0.1
			c0-4.6,3.2-8.3,7.9-8.3c4.6,0,7.9,3.8,7.9,8.3v0.1C628.4,672.8,625.2,676.5,620.5,676.5L620.5,676.5z M637.5,679.9h4.1v-8.2h5.1
			h0.1l5.7,8.2h4.8L651,671c3.3-0.9,5.6-3.2,5.6-7.1v-0.1c0-2-0.7-3.8-1.9-5.1c-1.5-1.5-3.8-2.3-6.7-2.3h-10.4V679.9L637.5,679.9z
			 M641.6,668.1v-7.9h6c3,0,4.8,1.4,4.8,3.9v0.1c0,2.4-1.9,3.9-4.8,3.9H641.6L641.6,668.1z M661.6,679.9h4.1v-23.4h-4.1V679.9
			L661.6,679.9z M669.9,679.9h4.2l2.4-5.7h11l2.4,5.7h4.3L684,656.3h-3.8L669.9,679.9L669.9,679.9z M678,670.6l4-9.4l4,9.4H678
			L678,670.6z M698.3,679.9h16.4v-3.7h-12.3v-19.7h-4.1V679.9L698.3,679.9z"/>
		<path d="M461.1,646.2h4.2l2.4-5.7h11l2.4,5.7h4.3l-10.3-23.6h-3.8L461.1,646.2L461.1,646.2z M469.3,636.9l4-9.4l4,9.4
			H469.3L469.3,636.9z M497.5,646.6c6.1,0,10-3.5,10-10.5v-13.3h-4.1v13.5c0,4.3-2.2,6.5-5.9,6.5c-3.7,0-5.9-2.3-5.9-6.7v-13.3h-4.1
			v13.5C487.6,643,491.4,646.6,497.5,646.6L497.5,646.6z M521.1,646.5c4.9,0,8.3-2.6,8.3-7v-0.1c0-3.9-2.6-5.7-7.5-6.9
			c-4.5-1.1-5.5-1.9-5.5-3.7v-0.1c0-1.5,1.4-2.8,3.8-2.8c2.1,0,4.2,0.8,6.3,2.4l2.2-3.1c-2.4-1.9-5.1-2.9-8.5-2.9
			c-4.6,0-8,2.8-8,6.8v0.1c0,4.3,2.8,5.8,7.8,7c4.3,1,5.3,1.9,5.3,3.6v0.1c0,1.8-1.6,2.9-4.1,2.9c-2.9,0-5.1-1.1-7.4-3l-2.5,2.9
			C514.1,645.3,517.5,646.5,521.1,646.5L521.1,646.5z M540,646.2h4.1v-19.6h7.4v-3.8h-18.9v3.8h7.4V646.2L540,646.2z M555.9,646.2
			h4.1V638h5.1h0.1l5.7,8.2h4.8l-6.3-8.9c3.3-0.9,5.6-3.2,5.6-7.1v-0.1c0-2-0.7-3.8-1.9-5.1c-1.5-1.5-3.8-2.3-6.7-2.3h-10.4V646.2
			L555.9,646.2z M560,634.4v-7.9h6c3,0,4.8,1.4,4.8,3.9v0.1c0,2.4-1.9,3.9-4.8,3.9H560L560,634.4z M578,646.2h4.2l2.4-5.7h11
			l2.4,5.7h4.3L592,622.6h-3.8L578,646.2L578,646.2z M586.1,636.9l4-9.4l4,9.4H586.1L586.1,636.9z M606.3,646.2h16.4v-3.7h-12.3
			v-19.7h-4.1V646.2L606.3,646.2z M627.2,646.2h4.1v-23.4h-4.1V646.2L627.2,646.2z M635.6,646.2h4.2l2.4-5.7h11l2.4,5.7h4.3
			l-10.3-23.6h-3.8L635.6,646.2L635.6,646.2z M643.7,636.9l4-9.4l4,9.4H643.7L643.7,636.9z M663.9,646.2h4v-16.7l12.9,16.7h3.4
			v-23.4h-4V639l-12.5-16.2h-3.8V646.2L663.9,646.2z"/>
	</g>
	<g>
		<rect x="310" y="62" width="31" height="31"/>
		<polygon points="527,93 527,62 496,62 465,62 465,93 496,93 "/>
		<rect x="682" y="62" width="31" height="31"/>
		<rect x="558" y="93" width="31" height="31"/>
		<polygon points="403,124 434,124 434,93 403,93 372,93 372,124 341,124 341,155 310,155 310,186 341,186 341,217 372,217 372,186 
			403,186 434,186 434,155 403,155 		"/>
		<polygon points="651,124 682,124 682,93 651,93 620,93 620,124 620,155 651,155 		"/>
		<rect x="682" y="124" width="31" height="31"/>
		<polygon points="806,155 837,155 837,124 806,124 806,93 837,93 837,62 806,62 775,62 744,62 744,93 744,124 744,155 775,155 		
			"/>
		<polygon points="558,217 558,186 558,155 527,155 527,124 496,124 465,124 434,124 434,155 465,155 496,155 496,186 527,186 
			527,217 527,248 558,248 		"/>
		<polygon points="620,217 620,186 589,186 589,217 589,248 620,248 		"/>
		<rect x="651" y="217" width="31" height="31"/>
		<rect x="310" y="248" width="31" height="31"/>
		<polygon points="465,248 496,248 496,217 465,217 434,217 434,248 434,279 403,279 403,248 372,248 372,279 341,279 341,310 
			372,310 403,310 403,341 372,341 372,372 403,372 403,403 434,403 434,372 434,341 465,341 465,310 496,310 496,279 465,279 		"/>
		<rect x="496" y="248" width="31" height="31"/>
		<rect x="558" y="248" width="31" height="31"/>
		<rect x="620" y="248" width="31" height="31"/>
		<polygon points="527,310 527,341 527,372 527,403 558,403 558,372 589,372 589,341 558,341 558,310 558,279 527,279 		"/>
		<rect x="589" y="279" width="31" height="31"/>
		<rect x="124" y="310" width="31" height="31"/>
		<rect x="310" y="310" width="31" height="31"/>
		<polygon points="744,310 775,310 775,279 775,248 775,217 775,186 744,186 713,186 682,186 682,217 713,217 744,217 744,248 
			744,279 713,279 713,248 682,248 682,279 682,310 713,310 713,341 682,341 651,341 620,341 620,372 620,403 651,403 682,403 
			682,372 713,372 744,372 744,341 		"/>
		<polygon points="806,341 806,372 837,372 837,341 868,341 868,310 837,310 837,279 837,248 806,248 806,279 806,310 775,310 
			775,341 		"/>
		<rect x="961" y="310" width="31" height="31"/>
		<polygon points="1054,310 1054,341 1054,372 1023,372 992,372 961,372 930,372 930,403 961,403 961,434 992,434 992,403 1023,403 
			1054,403 1085,403 1085,372 1085,341 1085,310 		"/>
		<rect x="279" y="341" width="31" height="31"/>
		<rect x="465" y="341" width="31" height="31"/>
		<rect x="868" y="341" width="31" height="31"/>
		<polygon points="124,372 124,341 93,341 93,372 93,403 124,403 		"/>
		<rect x="248" y="372" width="31" height="31"/>
		<rect x="341" y="372" width="31" height="31"/>
		<rect x="62" y="403" width="31" height="31"/>
		<polygon points="310,434 279,434 248,434 248,465 279,465 310,465 341,465 341,434 341,403 310,403 		"/>
		<polygon points="186,465 186,434 217,434 217,403 217,372 217,341 248,341 279,341 279,310 248,310 217,310 186,310 186,341 
			155,341 155,372 186,372 186,403 155,403 124,403 124,434 93,434 93,465 62,465 62,496 93,496 124,496 155,496 155,465 		"/>
		<polygon points="775,434 775,403 775,372 744,372 744,403 744,434 744,465 744,496 775,496 775,465 806,465 806,434 		"/>
		<polygon points="899,434 899,403 868,403 868,372 837,372 837,403 806,403 806,434 837,434 837,465 806,465 806,496 837,496 
			868,496 868,527 837,527 806,527 806,558 837,558 837,589 837,620 837,651 837,682 837,713 837,744 868,744 868,713 868,682 
			899,682 930,682 930,651 961,651 961,620 930,620 899,620 899,651 868,651 868,620 899,620 899,589 930,589 930,558 930,527 
			961,527 961,558 961,589 961,620 992,620 992,651 1023,651 1023,682 1023,713 1054,713 1054,682 1085,682 1085,651 1054,651 
			1054,620 1054,589 1023,589 1023,558 992,558 992,527 1023,527 1054,527 1085,527 1085,496 1085,465 1085,434 1054,434 1054,465 
			1023,465 1023,496 992,496 992,465 961,465 961,496 930,496 930,465 961,465 961,434 930,434 		"/>
		<polygon points="186,465 186,496 217,496 248,496 248,465 217,465 		"/>
		<rect x="372" y="465" width="31" height="31"/>
		<rect x="155" y="496" width="31" height="31"/>
		<rect x="248" y="496" width="31" height="31"/>
		<rect x="341" y="496" width="31" height="31"/>
		<polygon points="775,527 744,527 744,558 744,589 775,589 806,589 806,558 775,558 		"/>
		<polygon points="186,589 155,589 124,589 124,558 124,527 93,527 62,527 62,558 62,589 93,589 93,620 93,651 124,651 124,620 
			155,620 155,651 155,682 186,682 186,713 186,744 217,744 217,713 217,682 248,682 248,651 217,651 186,651 186,620 217,620 
			217,589 217,558 217,527 186,527 186,558 		"/>
		<rect x="248" y="620" width="31" height="31"/>
		<polygon points="403,651 403,620 403,589 403,558 403,527 372,527 372,558 341,558 310,558 279,558 248,558 248,589 279,589 
			310,589 310,620 341,620 341,589 372,589 372,620 341,620 341,651 372,651 		"/>
		<rect x="62" y="651" width="31" height="31"/>
		<rect x="310" y="651" width="31" height="31"/>
		<polygon points="93,682 93,713 93,744 124,744 124,713 155,713 155,682 124,682 		"/>
		<polygon points="279,713 310,713 310,682 279,682 248,682 248,713 		"/>
		<rect x="961" y="682" width="31" height="31"/>
		<polygon points="341,744 372,744 403,744 403,713 403,682 372,682 341,682 341,713 		"/>
		<rect x="775" y="713" width="31" height="31"/>
		<rect x="62" y="744" width="31" height="31"/>
		<rect x="124" y="744" width="31" height="31"/>
		<polygon points="248,744 217,744 217,775 248,775 279,775 279,744 		"/>
		<rect x="310" y="744" width="31" height="31"/>
		<rect x="527" y="744" width="31" height="31"/>
		<path d="M899,775h31v31h-31h-31v-31h-31v31h-31v31h-31v31v31h31v31v31h-31v31h31v31h-31v31h-31v31h31h31h31h31v-31h-31v-31h31v-31
			h-31v-31h31h31h31v31h-31v31v31v31h31h31v-31h-31v-31h31v-31v-31h31v-31h-31v-31h31v-31h-31v-31v-31v-31h31v-31h-31h-31v-31h-31
			v31h-31v31H899z M930,868v31v31h-31h-31h-31v-31v-31v-31h31h31h31V868z"/>
		<polygon points="1023,744 1023,775 1054,775 1085,775 1085,744 1085,713 1054,713 1054,744 		"/>
		<rect x="93" y="775" width="31" height="31"/>
		<rect x="155" y="775" width="31" height="31"/>
		<rect x="279" y="775" width="31" height="31"/>
		<polygon points="651,775 651,744 620,744 620,775 620,806 651,806 		"/>
		<rect x="62" y="806" width="31" height="31"/>
		<polygon points="217,806 217,837 248,837 279,837 279,806 248,806 		"/>
		<rect x="558" y="806" width="31" height="31"/>
		<polygon points="713,806 744,806 744,837 775,837 775,806 806,806 806,775 775,775 775,744 744,744 713,744 713,775 682,775 
			682,806 682,837 713,837 		"/>
		<polygon points="558,837 527,837 527,868 527,899 558,899 558,868 		"/>
		<rect x="589" y="837" width="31" height="31"/>
		<polygon points="682,899 682,868 682,837 651,837 651,868 620,868 620,899 589,899 558,899 558,930 558,961 589,961 589,930 
			620,930 651,930 651,899 		"/>
		<rect x="713" y="868" width="31" height="31"/>
		<rect x="868" y="868" width="31" height="31"/>
		<polygon points="1023,899 1054,899 1054,930 1085,930 1085,899 1085,868 1085,837 1085,806 1054,806 1054,837 1023,837 992,837 
			992,868 1023,868 		"/>
		<polygon points="310,899 310,930 310,961 341,961 341,930 372,930 403,930 403,899 403,868 434,868 434,837 403,837 403,806 
			434,806 465,806 465,837 465,868 434,868 434,899 465,899 496,899 496,868 496,837 496,806 496,775 465,775 465,744 434,744 
			434,775 403,775 372,775 372,806 341,806 341,837 310,837 310,868 341,868 341,899 		"/>
		<rect x="1023" y="930" width="31" height="31"/>
		<polygon points="434,961 403,961 403,992 372,992 372,1023 403,1023 403,1054 434,1054 434,1085 465,1085 465,1054 465,1023 
			465,992 434,992 		"/>
		<polygon points="496,961 496,930 465,930 434,930 434,961 465,961 465,992 496,992 		"/>
		<rect x="992" y="961" width="31" height="31"/>
		<rect x="310" y="992" width="31" height="31"/>
		<polygon points="682,992 682,961 713,961 713,930 682,930 651,930 651,961 620,961 620,992 651,992 651,1023 620,1023 620,992 
			589,992 558,992 527,992 527,1023 558,1023 558,1054 558,1085 589,1085 620,1085 651,1085 682,1085 682,1054 682,1023 713,1023 
			713,992 		"/>
		<rect x="1054" y="992" width="31" height="31"/>
		<polygon points="496,1054 496,1085 527,1085 527,1054 527,1023 496,1023 		"/>
		<rect x="713" y="1023" width="31" height="31"/>
		<polygon points="992,1023 992,1054 992,1085 1023,1085 1054,1085 1085,1085 1085,1054 1054,1054 1054,1023 1023,1023 		"/>
		<rect x="341" y="1054" width="31" height="31"/>
	</g>

	<g transform="translate(0,806) scale(2.17, 2.17)">
				<rect x="43.6" y="43.6" fill="none" width="70" height="70"/>
				<path d="M113.6,28.6h-70h-15v15v70v15h15h70h15v-15v-70v-15H113.6z M113.6,113.6h-70v-70h70V113.6z"/>
	</g>
	<g transform="translate(806,0) scale(2.17, 2.17)">
				<rect x="43.6" y="43.6" fill="none" width="70" height="70"/>
				<path d="M113.6,28.6h-70h-15v15v70v15h15h70h15v-15v-70v-15H113.6z M113.6,113.6h-70v-70h70V113.6z"/>
	</g>
	<g transform="translate(0,0) scale(2.17, 2.17)">
				<rect x="43.6" y="43.6" fill="none"  width="70" height="70"/>
				<path d="M113.6,28.6h-70h-15v15v70v15h15h70h15v-15v-70v-15H113.6z M113.6,113.6h-70v-70h70V113.6z"/>
	</g>
	<g transform="translate(62,62) scale(0.93, 0.93)">
			<rect x="66.7" y="66.7" width="100" height="100"/>
	</g>
	<g transform="translate(868,62) scale(0.93, 0.93)">
			<rect x="66.7" y="66.7" width="100" height="100"/>
	</g>
	<g transform="translate(62,868) scale(0.93, 0.93)">
			<rect x="66.7" y="66.7" width="100" height="100"/>
	</g>
</svg>
    </div>
  </div>
  )
  