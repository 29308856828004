import React, { Component } from 'react'
import { boolSession, lastUpdated } from '../lib/queries'
import { AWMQRCode } from '../components/AWMQRCode'
import QRCode from "react-qr-code";

export default class QueuedSessions extends Component {
  constructor() {
    super()
    this.state = {
      lastUpdated: 0,
      queuedSessions: []
    }
  }
  
  componentDidMount() {
    this.updateComponent()
    this.timer = setInterval(() => this.updateComponent(), 5000)
  }
  
  updateComponent = async () => {
    const checkLastUpdated = await lastUpdated()
    if (this.state.lastUpdated !== checkLastUpdated) {
      this.setState({lastUpdated: checkLastUpdated})
      const updatedQueuedSessions = await boolSession('isQueued')
      this.setState({queuedSessions: updatedQueuedSessions})
    }
  }
     
  render() {
    // Return blank if there's no queued sessions
    if (this.state.queuedSessions.length < 1) {
      return(
        <div className='queued-sessions'></div>
      )
    }

    const queue = this.state.queuedSessions
    // Splice out the Last Post Ceremony session from the queue
    const lpcIndex = queue.findIndex(session => session.type === "lpc")
    const lpcSession = queue.splice(lpcIndex,1)
    // Slice out the next (up to) 3 sessions
    const upcomingSessions = queue.slice(0,3)

    // Generate a component for the list of given sessions
    const SessionList = (givenSessions) => {
      const sessions = givenSessions.sessions
      const listItems = sessions.map((session) => (
        <SessionListItem key={session.id} session={session} />
      ))
      return (
        <ul>
          {listItems}
        </ul>
      )
    }

    // Generate a component for an item in the list of sessions
    const SessionListItem = (givenSession) => {
      const session = givenSession.session
      const availability = (session.isBookedOut ? 'Booked out' : session.available + ' available' )
      return (
        <li>
          <time>{session.time}</time>
          <p className={ session.availableClass }>{ availability }</p>
          {!session.isBookedOut && (
            <div class="qr">
              <QRCode value={ session.visitorBookingURL } bgColor={'#000000'} fgColor={'#FFFFFF'} size={128} />
            </div>
          )}
        </li>
      ) 
    }

    return(
      <>
      <div className='queued-sessions'>
        {!!upcomingSessions.length && (
          <div className='queued-gallery-sessions'>
          <h2>Upcoming sessions</h2>
          <SessionList sessions={upcomingSessions} />
          </div>
        )}
        {!!lpcSession.length && (
          <div className='queued-lpc-session'>
          <h2>Last Post Ceremony</h2>
          <SessionList sessions={lpcSession} />
          </div>
        )}
      </div>
      <AWMQRCode />
      </>
    )
  }
}
