import React, { Component } from 'react'
import { activeScans, activeCapacity, lastUpdated } from '../lib/queries'

export default class ScanCounter extends Component {
  constructor() {
    super()
    this.state = {
      lastUpdated: 0,
      activeScans: 0,
      activeCapacity: 0
    }
  }

  componentDidMount() {
    this.updateComponent()
    this.timer = setInterval(() => this.updateComponent(), 20000)
  }

  updateComponent = async () => {
    const checkLastUpdated = await lastUpdated()
    if (this.state.lastUpdated !== checkLastUpdated) {
      this.setState({lastUpdated: checkLastUpdated})
      const updatedActiveScans = await activeScans()
      this.setState({activeScans: updatedActiveScans})
      const updatedActiveCapacity = await activeCapacity()
      this.setState({activeCapacity: updatedActiveCapacity})
    }
  }

  render() {
    // If not between 8am and 6pm, hide the scan-counter
    const classes = (this.state.activeCapacity === 0 ? 'scan-counter hidden' : 'scan-counter')
    return(
      <div className={classes}>
        <p>Scanned in active sessions <span>{this.state.activeScans} / {this.state.activeCapacity}</span></p>
      </div>
    )
  }
}
