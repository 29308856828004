import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Australia/Sydney")

const now = () => dayjs.tz()
const parseDate = (date) => (date ? dayjs(date) : now())

export const isoDate = (date) => parseDate(date).format('YYYY-MM-DD')
export const shortTime = (date) => parseDate(date).format('h:mma')
export const longDate = (date) => parseDate(date).format('dddd D MMMM YYYY')
