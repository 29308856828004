// Check when the data was last updated on the server
export const lastUpdated = async () => {
  const lastUpdated = await fetch('/api/lastUpdated')
  if (lastUpdated.ok) {
    try {
      const response = await lastUpdated.json()
      return Number(response)
    }
    catch (error) {
      //console.log(error)
    }
  }
}

// Pull in all the session details from the server
export const allSessionDetails = async () => {
  const allSessionDetails = await fetch('/api/sessionDetails')
  if (allSessionDetails.ok) {
    try {
      const response = await allSessionDetails.json()
      return response
    }
    catch (error) {
      //console.log(error)
    }
  }
}

// Determine and return the sessions which satisfy a given Bool property
export const boolSession = async (bool) => {
  const sessionDetails = await allSessionDetails()
  const boolSession = sessionDetails.filter(session => session[bool])
  return boolSession
}

// Determine and return the total number of scans in currently active sessions
export const activeScans = async () => {
  let activeScans = 0
  const activeSessions = await boolSession('isActive')
  activeSessions.forEach(session => {
    activeScans += session.scans
  })
  return Number(activeScans)
}

// Determine and return the total capacity of currently active sessions
export const activeCapacity = async () => {
  let activeCapacity = 0
  const activeSessions = await boolSession('isActive')
  activeSessions.forEach(session => {
    activeCapacity += session.capacity
  })
  return Number(activeCapacity)
}
