import React, { Component } from 'react'
import { longDate, shortTime } from '../lib/date'

export default class Clock extends Component {
  constructor() {
    super()
    this.state = {
      nowDate: longDate(),
      nowTime: shortTime()
    }
  }

  componentDidMount() {
    this.updateComponent()
    this.timer = setInterval(() => this.updateComponent(), 1000)
  }

  updateComponent = () => {
    this.setState({nowDate: longDate()})
    this.setState({nowTime: shortTime()})
  }

  render() {
    return(
      <time className='clock'> { this.state.nowDate } <span>{ this.state.nowTime }</span></time>
    )
  }
}
