import React, { Component } from 'react'
import { boolSession, lastUpdated } from '../lib/queries'
import QRCode from "react-qr-code";

export default class CurrentSession extends Component {
  constructor() {
    super()
    this.state = {
      lastUpdated: 0,
      currentSession: []
    }
  }
  
  componentDidMount() {
    this.updateComponent()
    this.timer = setInterval(() => this.updateComponent(), 5000)
  }
  
  updateComponent = async () => {
    const checkLastUpdated = await lastUpdated()
    if (this.state.lastUpdated !== checkLastUpdated) {
      this.setState({lastUpdated: checkLastUpdated})
      const updatedCurrentSession = await boolSession('isCurrent')
      this.setState({currentSession: updatedCurrentSession})
    }
  }
  
  render() {
    // Return blank if there's no current sessions
    if (this.state.currentSession.length < 1) {
      return
    }

    const session = this.state.currentSession[0]
    const availability = (session.isBookedOut ? 'Booked out' : session.available + ' available' )
    const title = (session.type === "lpc" ? "Last Post Ceremony " : "Current session")
    
    return(
      <div className='current-session'>
        <h2>{ title }</h2>
        <time>{ session.time }</time>
        <p className={ session.availableClass }>{ availability }</p>
      </div>
    )
  }
}
